import IconCheck from "assets/svg/IconCheck";
import React, { ReactNode } from "react";
import "./style.scss";

interface Props {
	selected: string;
	onChange: CallableFunction;
	children: ReactNode;
	value: string;
	className?: string;
}
const RadioButton: React.FC<Props> = ({
	selected,
	onChange,
	children,
	value,
	className,
}) => {
	return (
		<div
			onClick={() => {
				onChange(value);
			}}
			className={"RadioButton" + (className ? " " + className : "")}
		>
			<div className="RadioButton__select-cell">
				<div
					className={`RadioButton__select ${
						value === selected && "RadioButton__select--selected"
					}`}
				>
					{value === selected && (
						<IconCheck className="RadioButton__checked" />
					)}
				</div>
			</div>
			{children}
		</div>
	);
};

export default RadioButton;
