import { Reducer } from "redux";
import { AddressInfoState, AddressInfoActionTypes } from "./types";

const initialState: AddressInfoState = {
	city: "",
	district: "",
	street: "",
	streetName: "",
	address: "",
	choise:"",
	dealer:"",
	cityName:"",
	distirctName:""
};

const reducer: Reducer<AddressInfoState> = (state = initialState, action) => {
	switch (action.type) {
		case AddressInfoActionTypes.SAVE_ADDRESS_INFO:
			return { ...state, ...action.payload };
		case AddressInfoActionTypes.DELETE:
			return { ...state, ...initialState };
		default: {
			return state;
		}
	}
};

export default reducer;
