export interface AddressInfo {
	city: string;
	cityName: string;
	district: string;
	street: string;
	streetName: string;
	address: string;
	choise: string;
	dealer: string;
	distirctName:string;
}

export enum AddressInfoActionTypes {
	SAVE_ADDRESS_INFO = "@@addressInfo/SAVE",
	DELETE = "@@addressInfo/DELETE",
}

export type AddressInfoState = {
	readonly city: string;
	readonly district: string;
	readonly street: string;
	readonly streetName: string;
	readonly address: string;
	readonly choise: string;
	readonly dealer: string;
	readonly cityName: string;
	readonly distirctName:string;
};
