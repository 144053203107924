import { Reducer } from "redux";
import { MsisdnState, MsisdnActionTypes } from "./types";

const initialState: MsisdnState = {
	number: "",
	timeout: "",
	otpStatus: "",
	otpSession:"",
	otpValidatedStatus:false,
	returnPage:""
};

const reducer: Reducer<MsisdnState> = (state = initialState, action) => {
	switch (action.type) {
		case MsisdnActionTypes.SAVE:
			return { ...state, ...action.payload };
		case MsisdnActionTypes.DELETE:
			return { ...state, ...initialState };
		default: {
			return state;
		}
	}
};

export default reducer;
