import React from "react";

interface Props {
	className?: string;
}

const IconSelectPackage: React.FC<Props> = ({ className }) => {
	return (
		<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" width="90" height="90">
			<title>svg+xml;base64,PHN2ZyB3aWR0aD0iMTMxIiBoZWlnaHQ9IjE</title>
			<defs>
				<image width="66" height="63" id="img4" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAAA/CAMAAABjNu7FAAAAAXNSR0IB2cksfwAAAkNQTFRFAAAAKFCiKVChKVCjKlCiKVCjKVCjKVCjKVCiKVCiKFCjKVGiKVCiKFCjKFCiKFCjKFCfKVCiKlCiKlGjKlGjKVCiKlChKVCjKVGjKVGjKVCjKVCiKVChKVGiKlCiJVCfKlCiKlGjKFGiKFGiKlGjKlGjJ1ChJVCfKlGiKVGhKVGhKlGjKVChJ1CfKlGjKlCiKVChKlGjKlChKFCfKlCiKlGjKlGjKlGjKlGjKlGjKlGiKVCiKlGiKVCiKlGjKlGjKVCiKlChKVCiKVCiKVCjKlGjKVCiIFCfKlGjKFGhKVCiKlGjKlGjKVCiKVCiIFCfKVGjKlGjKlGjKVGjKVGiKFCfKVCiKFCjIFCfKVCjKVGjKVGiKVGiKVGiKVGjKFCiIFCfKlGjKFCfKFCfKFCfKVGhKlGjKlChKlGjKlGjKVCiKVGhKVGiKVGiJVCfJ1GhKlGjKVCiKVChKVCgJVCfJVCfJ1GhKFGiIFCfKVCiKlGjKVCiKVChKFCfKFCiKlCiKlCiKlCiKlGjKFCiJ1CfKlGjKVCiKlCiKlChKVCjKVCiKFGhKVGjKVGiKVCiKlGjKlGjKVCjKVCiKVCiKVCiKlCiKVGhKlGjKFCiKlGiKVCjKlGjKVCiKlCiKlGjKlGjKVGhKlChKlCiKlGiKVChKFCfKlGjKVGjKlGiKlGiKFCfKVCiKlChKlCiKVGjKVChKVCjKVCiKFCiKVCjKVCjKVCiKVGjKlGjKlCiJ1GiKVCiKVCiKVGjKVCiKlGiKVGiIFCf5jQLvQAAAMF0Uk5TAAgpea3Mx6hwHRlct38zCgVAtfv/tBwSqsZRCRqjdwtZvyc4+MU1FbFXX9FOJ76PZtVVK5n63+/07pWVy3Lc6oCAwNBA8YULr09S9fywGgeb5f25RQSqVQU7WXmAYkcVAtQSFQdcz0svvCsWlWsCMKAZYFUgEDo/BFrtfyUBLvSOFJxGILp4lCo8PCngqyDm038NelErUspAwMDsi4uqxmFSkkJAHLhkJMcONApc6jkg1RANQanr4aQ6JpncuFYTAbOTySwAAAI9SURBVHic7db3UxNBFAfwpyjYRbP2Go1d1CMkoigbkNiCxgYWQI3Gjj1WVGxgN1ixd+yIvbc/zXIXZl6yb303gz8wk++v7zufudud21uAFi1TWrVOTGpam7bQmHbt01IVpQ4dO3UGSO/S1SHU6dY9JvToSVREr959oG8/aipE/wEWMZDuOAfBYBc9HjLUFIYNpztiBIzUTEeNNomMMZrSWBinmRqZJuF2akpZFuHxOlG8KmI87hjZiJgw0Y2SoyIm4c7kXERIH6DkqYh83Mk0kkSSaBpiSgGKz2uf8EgUv8M+oU4TEFNZxLTpGmIGi4CZAQoonDWbRwTnzC1UAfP88xcUWYS/eCFKMSZg0eIlJaWJKVu6bDlYhEOGUGQcAVC0YmViwn9HnOP3H1mlIVavYRFrycUWYt16FrGhnP6dbQyziE2by4nncG3ZyhL+xL0tT5HtEVTasXNXYiKEqMjuPSH1k+6t2LefRxyopJbLdfAQSwgepnct+wiLOFpFE6KaRWjPi2N2iPiDz/x4s34XwsdPFChy8lQ8cdqHckbGiLMVoahUJFpzLogJ6idw/kKUekt5kUdcIrdciKoIi7isWezaKyzCxo3vvxOBq/hcvWafuH4Dn6s37RPN6H6RJJoX4TJuoRgqQuLObT8i1GHe+O5opjV3GcQ9uK+Z1j4wibqHmtIjePyEnuY8tVYuhe7kPoPn9QY1fdEQW/yXr6iOLHkN8Obtu/eq4YePn9IbN/Dzl68eRSfw7fuPn78AZmuLT1M1FKwAAAAASUVORK5CYII=" />
			</defs>
			<style>
			</style>
			<use id="Layer 2" href="#img4" x="12" y="13" />
		</svg>

	);
};

export default IconSelectPackage;
