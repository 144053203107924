import Container from "App/components/Container";
import PageWrapper from "App/components/PageWrapper";
import routes from "App/routes";
import leftImage from "assets/images/fikriye.png";
import rightImage from "assets/images/gozluklu.png";


import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import "./style.scss";
import Modal from "App/components/Modal";
import IconError from "assets/svg/IconError";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { LoadingSpinnerActionTypes } from "store/loadingSpinner/types";
import { MsisdnActionTypes, MsisdnState } from "store/msisdn/types";
import { ApplicationState } from "store";
import { apiEndpoints } from "App/api/endpoints";
import api from "App/api";
import ProcessBar from "App/components/ProcessBar";  
import { getRecaptchaScore } from "App/components/ReCaptchaV3";
import ReCAPTCHA from "react-google-recaptcha";

interface Props {
	type?: string;
} 
const MNTInfo: React.FC<Props> = ({ type }) => {
	const { t } = useTranslation();
	const [msisdn, setMsisdn] = useState("");
	const [showErrorModal, setShowErrorModal] = React.useState(false);
	const [errorModalMessage, setErrorModalMessage] =
		React.useState<string>("");
	const [token, setToken] = useState("");

	const history = useHistory();  
	const reRef = useRef<ReCAPTCHA>(null);
	function useQuery() {
		const { search } = useLocation();

		return React.useMemo(() => new URLSearchParams(search), [search]);
	}

	const existMsisdn: MsisdnState = useSelector(
		(state: ApplicationState) => state.msisdn,
		shallowEqual
	);
	const [isCaptchaSuccessful, setIsCaptchaSuccess] = React.useState(false)

	let query = useQuery();
	console.log("query ", query.get("code"))
	const queryCode = query.get("code")

	const dispatch = useDispatch();

	const onChange = async (val:any) => {
		setIsCaptchaSuccess(!isCaptchaSuccessful)
		setToken(val);
		
		//console.log("captcha value: ", val);
	  }

	const handleOnClick = async () => {
 

		
		dispatch({
			type: LoadingSpinnerActionTypes.SHOW_LOADING_SPINNER,
		});

		dispatch({
			type: MsisdnActionTypes.SAVE,
			payload: {
				number: msisdn,
				otpStatus: "0",
				otpValidatedStatus: false,
				returnPage: routes.mnt,
			},
		});


 
				

		//comment
		// const apiEndpoint = apiEndpoints.createOTP;
		// api
		// 	.post(apiEndpoint, {
		// 		msisdn: msisdn,
		// 		mnt: true
		// 	})
		// 	.then((response) => {
		// 		const data = response.data;

		// 		if (data.resultCode === 0) {
		// 			history.push({
		// 				pathname: routes.otpValidate,
		// 				state: {
		// 					hasPrev: true,
		// 					queryCode: queryCode,
		// 					operation: true,
		// 				},
		// 			});
		// 		} else {
		// 			setErrorModalMessage(t("switchNumber.otpHata"));
		// 			setShowErrorModal(true);
		// 		}
		// 	})
		// 	.catch((e) => {
		// 		setErrorModalMessage(t("switchNumber.otpHata"));
		// 		setShowErrorModal(true);

		// 	})
		// 	.then(() => {
		// 		dispatch({
		// 			type: LoadingSpinnerActionTypes.HIDE_LOADING_SPINNER,
		// 		});
		// 	});


	 
		const checkMsisdnStatus = apiEndpoints.isPrePos;
		api
			.post(checkMsisdnStatus, {
				msisdn: msisdn
			})
			.then(async (response) => {
				const data = response.data;
				if (data.resultCode < 0) {
				// console.log('halli grecaptcha', await getRecaptchaScore(
				// 	process.env.REACT_APP_GRECAPTCHA_SITE_KEY
				// ))	
		// if (
		// 	!(window as any).grecaptcha ||
		// 	(await getRecaptchaScore(
		// 		process.env.REACT_APP_GRECAPTCHA_SITE_KEY
		// 	)) < 0.5
		// ) {
		// 	dispatch({
		// 		type: LoadingSpinnerActionTypes.HIDE_LOADING_SPINNER,
		// 	});
		// 	setErrorModalMessage(t("validation.recaptchaErrorMessage"));
		// 	setShowErrorModal(true);
		// 	return;
		// }

					//const token = await reRef.current?.executeAsync();
					reRef.current?.reset();
					
					const apiEndpoint = apiEndpoints.createOTP;
					api
						.post(apiEndpoint, {
							msisdn: msisdn,
							token: token,
							mnt: true
						})
						.then((response) => {
							const data = response.data;
							setIsCaptchaSuccess(false);  
							if (data.resultCode === 0) {
								history.push({
									pathname: routes.otpValidate,
									state: {
										hasPrev: true,
										queryCode: queryCode,
										operation: true,
									},
								});
							} else {
								setErrorModalMessage(t("switchNumber.otpHata"));
								setShowErrorModal(true);
							}
						})
						.catch((e) => {
							setErrorModalMessage(t("switchNumber.otpHata"));
							setShowErrorModal(true);

						})
						.then(() => {
							dispatch({
								type: LoadingSpinnerActionTypes.HIDE_LOADING_SPINNER,
							});
						});
				}
				else {
					setErrorModalMessage(t("mnt.numHata"));
					setShowErrorModal(true);
				}
			})
			.catch((e) => {
				setErrorModalMessage(t("mnt.numHata"));
				setShowErrorModal(true);

			})
			.then(() => {
				dispatch({
					type: LoadingSpinnerActionTypes.HIDE_LOADING_SPINNER,
				});
			});

	};

	const ErrorModal = () => (
		<Modal show={showErrorModal}>
			<div className="Modal__content PersonalInfo__modal">
				<IconError className="Modal__error-icon" />
				<h2 className="Modal__title">{t("modal.failTitle")}</h2>
				<p className="Modal__description">{errorModalMessage}</p>
				<button
					type="button"
					className="button Modal__button"
					onClick={() => {
						setShowErrorModal(false);
					}}
				>
					{t("modal.OK")}
				</button>
			</div>
		</Modal>
	);

	return (
		<>

			<PageWrapper className="interior">
				<div className="grid">
					<div className="grid__rowProcess">
						<div className="grid__column grid__columnWitoutPadding">
							<ProcessBar fromPage={routes.mnt} step={0} />
						</div>
					</div>
				</div>
				<Container className="Container--align-contents-middle">
					<div className="grid">
						<div className="grid__rowU">
							<div className="grid__columnU grid__alignStart">
								<img
									src={leftImage}
								/>
							</div>
							<div className="grid__columnU4">
								<h2 className="SwitchNumberSelection__heading heading--center">
									{t("mnt.title")}
									{type}
								</h2>
								<h2 className="SwitchNumberSelection__subHeading heading--center">
									{t("mnt.subTitle")}
									{type}
								</h2>

								<div className="SwitchNumberSelection__card">
									<div className="SwitchNumberSelection__text">
										<p>{t("mnt.text")}</p>
									</div>
									<div className="SwitchNumberSelection__radio-group">
										<div className="NumberSelection__form-input-container">
											<NumberFormat
												label="asd"
												format="0 (###) ### ## ##"
												allowEmptyFormatting
												mask="_"

												className="form__input NumberSelection__form-input"
												value={msisdn}
												onValueChange={(values) => {
													const { value } = values;
													setMsisdn(value);
												}}
											/>
										</div>

										{/* 										
										<div className="form__group">
													<TextField id="contactNumber" name="contactNumber" label={t("personalInfo.labelContactNumber")} variant="outlined"
														autoCapitalize="words" type="text"
														defaultValue={defaultFormValues.contactNumber} onChange={handleChange} fullWidth />
												</div> */}
									</div>

					
								

									<div className="NumberSelection__buttons">
									
										<button
											type="button"
											className="button"
											disabled={
												(!isCaptchaSuccessful) ||
												(msisdn.length === 10
													? false : true)
											}
											onClick={handleOnClick}
										>
											{t("switchNumber.buttonSearch")}
										</button> 

									</div>

									<div className="NumberSelection__buttons"> 
									<ReCAPTCHA   
											sitekey={`${process.env.REACT_APP_V2_RECAPTCHA_SITE_KEY}`}
											onChange={onChange} 
											ref={reRef}
											/>	

								</div>
								</div>
							</div>
							<div className="grid__columnU6 grid__alignEnd">
								<img
									src={rightImage}
								/>
							</div>
						</div>
					</div>
				</Container>
			</PageWrapper>
			<ErrorModal />
		</>
	);
};

export default MNTInfo;
