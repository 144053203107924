import i18n from "../../i18n";

import differenceInYears from "date-fns/differenceInYears";
import parseDate from "date-fns/parse";
import {
	validateKktcKimlikNo,
	validateTcKimlikNo,
	validateRequired,
} from "./validators";

export const getFormattedMsisdn = (msisdn: string | undefined) => {
	if (msisdn) {
		msisdn = "0" + msisdn;
		msisdn = msisdn.slice(0, 4) + " " + msisdn.slice(4);
		msisdn = msisdn.slice(0, 8) + " " + msisdn.slice(8);
		msisdn = msisdn.slice(0, 11) + " " + msisdn.slice(11);
		return msisdn;
	}
	return "0";
};

export const getSelectObjects = (
	array: any,
	sortBy: string = "",
	sortDirection: string = "DESC"
) => {
	let newArray: any = [];
	array.forEach((item: any) => {
		newArray.push({
			value: item.id,
			label: i18n.language === "tr" ? item.name_tr : item.name_en,
		});
	});

	if (sortBy === "") {
		return newArray;
	}
	let direction = 1;
	if (sortDirection === "ASC") {
		direction = -1;
	}

	return newArray.sort((a: any, b: any) => {
		if (i18n.language) {
			return direction === 1
				? a[sortBy].localeCompare(b[sortBy], i18n.language)
				: b[sortBy].localeCompare(a[sortBy], i18n.language);
		}

		if (a[sortBy] < b[sortBy]) {
			return -1 * direction;
		}
		if (a[sortBy] > b[sortBy]) {
			return 1 * direction;
		}
		return 0;
	});
};

export const copyToClipboard = (text: string = "") => {
	if (!text) {
		return false;
	}

	if (
		(window as any).clipboardData &&
		(window as any).clipboardData.setData
	) {
		// Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
		return (window as any).clipboardData?.setData("Text", text);
	} else if (
		document.queryCommandSupported &&
		document.queryCommandSupported("copy")
	) {
		var textarea = document.createElement("textarea");
		textarea.textContent = text;
		textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
		document.body.appendChild(textarea);
		textarea.select();
		try {
			return document.execCommand("copy"); // Security exception may be thrown by some browsers.
		} catch (ex) {
			//console.warn(i18n.t("Kopyalama başarısız. Tarayıcınız bu işlemi desteklemiyor."), ex);
			// alert(
			// 	i18n.t(
			// 		"Kopyalama başarısız. Tarayıcınız bu işlemi desteklemiyor."
			// 	)
			// );
			return false;
		} finally {
			document.body.removeChild(textarea);
		}
	}
};

export const validateIdNumber = (value: string, type: string) => {
	switch (type) {
		case "1":
			return validateKktcKimlikNo(value);
		// case "18":
		// 	return validateKktcKimlikNo(value, 10);
		case "2":
			return validateTcKimlikNo(value);
		default:
			validateRequired(value);
	}
};

export const getAge = (dobString: string = "") => {
	if (dobString === "") return 0;
	const today = new Date();
	const dob = parseDate(dobString, "dd/MM/yyyy", new Date());

	return differenceInYears(today, dob);
};

export const base64ImageToBlob = (str: string) => {
	// extract content type and base64 payload from original string
	const pos = str.indexOf(";base64,");
	const type = str.substring(5, pos);

	const b64 = str.substr(pos + 8);

	// decode base64
	const imageContent = atob(b64);

	// create an ArrayBuffer and a view (as unsigned 8-bit)
	const buffer = new ArrayBuffer(imageContent.length);
	const view = new Uint8Array(buffer);

	// fill the view, using the decoded base64
	for (let n = 0; n < imageContent.length; n++) {
		view[n] = imageContent.charCodeAt(n);
	}

	// convert ArrayBuffer to Blob
	const blob = new Blob([buffer], { type: type });

	return blob;
};

export const blobToFile = (theBlob: Blob, fileName: string): File => {
	//A Blob() is almost a File() - it's just missing the two properties below which we will add
	const lastModifiedDate = new Date();

	//Cast to a File() type
	return new File([theBlob], fileName, {
		lastModified: lastModifiedDate.getTime(),
		type: theBlob.type,
	});
};

export const isIE = () => {
	return window.navigator.userAgent.indexOf("MSIE ") > 0;
};

export const isNotIE = () => {
	return !(window.navigator.userAgent.indexOf("MSIE ") > 0);
};

export const getFileExtension = (fileName: string) => {
	return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
};

export const moveToItemsWithKeysToBeginning = (
	data: Array<any>,
	key: string,
	values: Array<any>
) => {
	values.reverse().forEach((value) => {
		const itemIndex = data.findIndex((item) => item[key] === value);
		if (itemIndex >= 0) {
			data.splice(
				0, // new index,
				0, // no removal
				data.splice(itemIndex, 1)[0] // detach the item and return it
			);
		}
	});
	return data;
};

//TODO: THIS IS TEMPORARY HARDCODING TARIFF IDs
export const getMappedTariffId = (id: string) => {
	switch (id) {
		// ASKER TARİFE
		case "31eca5c1-57e8-4178-ad51-42b1b24ec5b1":
			return "1034";
		// GNÇTRKCLL TARİFE
		case "ed909649-7337-45cc-95cf-d948aee22a6b":
			return "1025";
		// STANDART TARİFE
		case "5f6cfa77-4af3-45e6-b25c-c57bc2253222":
			return "1011";
	}

	return "0";
};
