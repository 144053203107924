import { combineReducers, Reducer } from "redux";
import { createStore, applyMiddleware, Store } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist"; // imports from redux-persist
import storageSession from "redux-persist/lib/storage/session";

import { MsisdnState } from "store/msisdn/types";
import msisdnReducer from "store/msisdn/reducer";
import { PersonalInfoState } from "store/personalInfo/types";
import personalInfoReducer from "store/personalInfo/reducer";
import { PackageInfoState } from "./packageInfo/types";
import packageInfoReducer from "store/packageInfo/reducer";
import { AddressInfoState } from "store/addressInfo/types";
import addressInfoReducer from "store/addressInfo/reducer";
import { ResultState } from "store/result/types";
import resultReducer from "store/result/reducer";
import { LoadingSpinnerState } from "store/loadingSpinner/types";
import loadingSpinnerReducer from "store/loadingSpinner/reducer";

// The top-level state object
export interface ApplicationState {
	msisdn: MsisdnState;
	personalInfo: PersonalInfoState;
	packageInfo: PackageInfoState;
	addressInfo: AddressInfoState;
	result: ResultState;
	loadingSpinner: LoadingSpinnerState;
}

export const reducers: Reducer<ApplicationState> = combineReducers<ApplicationState>(
	{
		msisdn: msisdnReducer,
		personalInfo: personalInfoReducer,
		packageInfo: packageInfoReducer,
		addressInfo: addressInfoReducer,
		loadingSpinner: loadingSpinnerReducer,
		result: resultReducer,
	}
);

const persistConfig = {
	// configuration object for redux-persist
	key: "root",
	storage: storageSession, // define which storage to use
	blacklist: ["loadingSpinner"], // exclude from persistence
	// whitelist: [] // include to persistence (remove if this attribute not explicitly including)
};

const persistedReducer = persistReducer(persistConfig, reducers); // create a persisted reducer

const store: Store<ApplicationState> = createStore(
	persistedReducer, // pass the persisted reducer instead of rootReducer to createStore
	applyMiddleware(thunk) // add any middlewares here
);

const persistor = persistStore(store); // used to create the persisted store, persistor will be used in the next step

export { store, persistor };
