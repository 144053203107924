import React from "react";

interface Props {
	className?: string;
}

const IconCellPhoneList: React.FC<Props> = ({ className }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 195 195"
			version="1.1"
			focusable="false"
			className={className ? className : ""}
		>
			<defs>
				<filter
					colorInterpolationFilters="sRGB"
					height="1.28"
					id="iconphonelistshadowfilter1047"
					x="-0.11"
					y="-0.11"
					width="1.21"
				>
					<feFlood
						floodOpacity="0.498039"
						floodColor="rgb(0,0,0)"
						result="flood"
					/>
					<feComposite
						in="flood"
						in2="SourceGraphic"
						operator="in"
						result="composite1"
					/>
					<feGaussianBlur
						in="composite1"
						stdDeviation="7"
						result="blur"
					/>
					<feOffset dx="0" dy="14" result="offset" />
					<feComposite
						in="SourceGraphic"
						in2="offset"
						operator="over"
						result="composite2"
					/>
				</filter>
			</defs>
			<g transform="translate(16.72,16.72)">
				<g transform="translate(-26.458344,-26.458344)">
					<circle
						fill="#0c4ba3"
						filter="url(#iconphonelistshadowfilter1047)"
						id="path833"
						cx="102.45834"
						cy="102.45834"
						r="76"
					/>
					<g transform="translate(-1.6964188,1.9583435)">
						<rect
							fill="#ffffff"
							id="rect835"
							width="42"
							height="74"
							x="83.154762"
							y="63.5"
							rx="8"
						/>
						<circle
							fill="#0c4ba3"
							id="path837"
							cx="104.15476"
							cy="127.4256"
							r="4"
						/>
					</g>
					<g transform="translate(-49.21175,-81.335613)">
						<path
							fill="#ffc900"
							d="m 160.74368,155.04532 c -1.14904,0 -2.07378,0.9162 -2.07378,2.05413 v 32.98559 0.90589 2.05413 2.9962 c 0.33755,0.0254 0.68346,-0.0884 0.93896,-0.35036 l 2.47789,-2.54145 c 0.0318,-0.0326 0.0559,-0.0693 0.0832,-0.10439 0.0273,0.0351 0.0514,0.0723 0.0832,0.1049 l 2.47789,2.54145 c 0.2555,0.26196 0.60141,0.37577 0.93896,0.35037 0.33755,0.0254 0.68346,-0.0884 0.93896,-0.35037 l 2.47788,-2.54145 c 0.0318,-0.0326 0.0559,-0.0698 0.0832,-0.1049 0.0273,0.0351 0.0514,0.0723 0.0832,0.1049 l 2.47789,2.54145 c 0.2555,0.26196 0.60141,0.37577 0.93896,0.35037 0.33755,0.0254 0.68294,-0.0884 0.93844,-0.35037 l 2.47789,-2.54145 c 0.0318,-0.0326 0.0564,-0.0698 0.0837,-0.1049 0.0273,0.0351 0.0514,0.0723 0.0832,0.1049 l 2.47789,2.54145 c 0.2555,0.26196 0.60089,0.37577 0.93844,0.35037 0.33755,0.0254 0.68346,-0.0884 0.93896,-0.35037 l 2.47789,-2.54145 c 0.0318,-0.0326 0.0559,-0.0698 0.0832,-0.1049 0.0273,0.0351 0.0514,0.0723 0.0832,0.1049 l 2.47788,2.54094 c 0.2555,0.26194 0.60141,0.37576 0.93896,0.35036 v -2.9962 -2.05413 -0.90589 -32.98559 c 0,-1.13793 -0.92473,-2.05413 -2.07377,-2.05413 z m -0.0646,37.99716 c 0.0217,6.6e-4 0.0428,0.003 0.0646,0.003 h -0.0646 z m 23.98148,0 v 0.003 h -0.0646 c 0.0219,0 0.0429,-0.002 0.0646,-0.003 z"
						/>
						<rect
							fill="#0c4ba3"
							width="17"
							height="3"
							x="162.37402"
							y="158.74765"
							rx="1"
						/>
						<rect
							fill="#0c4ba3"
							width="8"
							height="3"
							x="162.37402"
							y="167.32205"
							rx="1"
						/>
						<rect
							fill="#0c4ba3"
							width="11"
							height="3"
							x="162.37402"
							y="175.89644"
							rx="1"
						/>
						<rect
							fill="#0c4ba3"
							width="14"
							height="3"
							x="162.37402"
							y="184.47086"
							rx="1"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default IconCellPhoneList;
