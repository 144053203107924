import { Reducer } from "redux";
import { ResultState, ResultActionTypes } from "./types";

const initialState: ResultState = {
	msisdn: "",
	confirmationCode: "",
	status: "",
	clickNo: "",
};

const reducer: Reducer<ResultState> = (state = initialState, action) => {
	switch (action.type) {
		case ResultActionTypes.SAVE:
			return { ...state, ...action.payload };
		case ResultActionTypes.DELETE:
			return { ...state, ...initialState };
		default: {
			return state;
		}
	}
};

export default reducer;
