import React, { ChangeEvent } from "react";
import "./style.scss";

interface Option {
	label: string | number;
	value: string | number | readonly string[] | undefined;
}

interface Props {
	ref?: any;
	id?: string;
	name?: string;
	options?: Array<Option> | undefined | null;
	classes?:
		| { selectClasses?: string; optionClasses?: string }
		| undefined
		| null;
	onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
	value?: string | number | readonly string[] | undefined;
	placeholder?: string;
}

const Select = React.forwardRef(
	(props: Props, ref?: React.Ref<HTMLSelectElement>) => {
		const { classes, name, id, onChange, value, placeholder, options } =
			props;

		return options ? (
			<select
				ref={ref}
				className={"Select " + (classes ? classes?.selectClasses : "")}
				name={name}
				id={id}
				onChange={onChange}
				value={value}
			>
				
				 
				<option>{placeholder}</option> 
				{options.map((item: Option, index: number) => (
					<option
						key={index}
						className={props.classes?.optionClasses}
						value={item.value}
					>
						{item.label}
					</option>
				))}
			</select>
		) : (
			<select
				className={"Select " + classes ? classes?.selectClasses : ""}
				disabled
			></select>
		);
	}
);

export default Select;
