import i18n from "../../i18n";

export const tPeriodUnit = (value: string) => {
	switch (value) {
		case "Month":
			return i18n.t("period.month");

		case "Year":
			return i18n.t("period.year");

		case "Week":
			return i18n.t("period.week");

		case "Day":
			return i18n.t("period.day");

		default:
			return value;
	}
};

export const tPriceDescription = (value: string) => {
	switch (value) {
		case "Ucretsiz":
			return i18n.t("price.free");

		default:
			return value;
	}
};

export const tCurrency = (value: string) => {
	switch (value) {
		case "Credit":
			return i18n.t("currency.credit");

		default:
			return value;
	}
};
