export interface LoadingSpinner {
	show: boolean;
}

export enum LoadingSpinnerActionTypes {
	SHOW_LOADING_SPINNER = "@@loadingSpinner/SHOW",
	HIDE_LOADING_SPINNER = "@@loadingSpinner/HIDE",
}

export type LoadingSpinnerState = {
	readonly show: boolean;
};
