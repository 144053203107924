
export const validator = (values:any, fieldName:string) => {
	let errors = {};

	console.log("validate started");
	switch (fieldName) {
	  case "nameSurname":
		validateNameSurname(values.nameSurname, errors);
		break;
	  
	  default:
	}
	return errors;
  };

  function validateNameSurname(nameSurname:string, errors:any) {
	let result = true;

	if (!nameSurname) {
	  errors.nameSurname = "Olmadııı";
	  errors.nameSurname.type="required"
	  result = false;
	} else {
	  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	  result = re.test(String(nameSurname).toLowerCase());
	  if (!result) errors.email = "Invalid Email address";
	}
	return result;
  }


export const isPhoneNumberValid = (v: string) => {
	const clearedTel = v.replace(/[^0-9]/g, "");
	return clearedTel.length < 11 ? false : true;
};

export const containsOnlyText = (v: string) => {
	return !/\d/.test(v);
};

export const isDateValid = (date: string) => {
	const dateformat =
		/^(0?[1-9]|[12][0-9]|3[01])[/\-.](0?[1-9]|1[012])[/\-.]\d{4}$/;
	// Match the date format through regular expression
	if (date.match(dateformat)) {
		//Test which seperator is used '/', '.' or '-'
		const opera1 = date.split(".");
		const opera2 = date.split("-");
		// Extract the string into month, date and year
		let splitDate = date.split("/");

		if (opera1.length > 1) {
			splitDate = opera1;
		}

		if (opera2.length > 1) {
			splitDate = opera2;
		}

		if (splitDate.length < 3) {
			return false;
		}

		var dd = parseInt(splitDate[0]);
		var mm = parseInt(splitDate[1]);
		var yy = parseInt(splitDate[2]);

		// Create list of days of a month [assume there is no leap year by default]
		const listOfDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
		if (mm === 1 || mm > 2) {
			if (dd > listOfDays[mm - 1]) {
				return false;
			}
		}

		if (mm === 2) {
			var leapYear = false;
			if ((!(yy % 4) && yy % 100) || !(yy % 400)) {
				leapYear = true;
			}
			if (leapYear === false && dd >= 29) {
				return false;
			}
			if (leapYear === true && dd > 29) {
				return false;
			}
		}
	} else {
		return false;
	}
	return true;
};

export const isTwoOrMoreWords = (value: string) => {
	if (value === "" || value === null) {
		return false;
	}

	if (
		value.split(" ").filter(function (el) {
			return !(el === null || el === "");
		}).length < 2
	) {
		return false;
	}

	return true;
};

export const validateTcKimlikNo = (value: string) => {
	let odd = 0;
	let even = 0;
	let result = 0;
	let sum = 0;
	const wrong = [
		11111111110, 22222222220, 33333333330, 44444444440, 55555555550,
		66666666660, 7777777770, 88888888880, 99999999990,
	];

	if (value.length !== 11) return false;
	if (!/^\d+$/.test(value)) return false;
	if (value[0] === "0") return false;

	odd =
		parseInt(value[0]) +
		parseInt(value[2]) +
		parseInt(value[4]) +
		parseInt(value[6]) +
		parseInt(value[8]);
	even =
		parseInt(value[1]) +
		parseInt(value[3]) +
		parseInt(value[5]) +
		parseInt(value[7]);

	odd = odd * 7;
	result = Math.abs(odd - even);
	if (result % 10 !== parseInt(value[9])) return false;

	for (let i = 0; i < 10; i++) {
		sum += parseInt(value[i]);
	}

	if (sum % 10 !== parseInt(value[10])) return false;

	if (wrong.toString().indexOf(value) !== -1) return false;

	return true;
};

export const validateKktcKimlikNo = (value: string) => {
	if (!/^\d+$/.test(value)) return false;
	if (value.length === 6 || value.length === 10) return true;
	return false;
};

export const validateRequired = (value: string) => {
	if (value.length < 1) return false;
	return true;
};
