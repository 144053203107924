import React from "react";

interface Props {
	className?: string;
}

const IconError: React.FC<Props> = ({ className }) => {
	return (
		<svg
			viewBox="0 0 1024 1024"
			xmlns="http://www.w3.org/2000/svg"
			focusable="false"
			className={className ? className : ""}
		>
			<path
				fill="currentColor"
				d="M512 0c-283.142 0-512 228.857-512 512 0 283.129 228.857 512 512 512 283.129 0 512-228.871 512-512 0-283.142-228.871-512-512-512zM767.999 695.801l-72.199 72.199-183.801-183.801-183.801 183.801-72.199-72.199 183.801-183.801-183.801-183.801 72.199-72.199 183.801 183.801 183.801-183.801 72.199 72.199-183.801 183.801 183.801 183.801z"
			/>
		</svg>
	);
};

export default IconError;
