import { Reducer } from "redux";
import { LoadingSpinnerState, LoadingSpinnerActionTypes } from "./types";

const initialState: LoadingSpinnerState = {
	show: false,
};

const reducer: Reducer<LoadingSpinnerState> = (
	state = initialState,
	action
) => {
	switch (action.type) {
		case LoadingSpinnerActionTypes.SHOW_LOADING_SPINNER:
			return { ...state, show: true };
		case LoadingSpinnerActionTypes.HIDE_LOADING_SPINNER:
			return { ...state, show: false };
		default: {
			return state;
		}
	}
};

export default reducer;
