import Container from "App/components/Container";
import Modal from "App/components/Modal";
import PageWrapper from "App/components/PageWrapper";
import RadioButton from "App/components/RadioButton";
import { getFormattedMsisdn } from "App/helpers/functions";
import routes from "App/routes";
import IconError from "assets/svg/IconError";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { ApplicationState } from "store";
import { MsisdnActionTypes, MsisdnState } from "store/msisdn/types";
import { PhoneNumber } from "type";
import "./style.scss";
import leftImage from "assets/images/fikriye.png";
import rightImage from "assets/images/gozluklu.png";
import ProcessBar from "App/components/ProcessBar";


interface Props { }

interface LocationState {
	from: {
		pathname: string;
	};
	phoneNumbers?: Array<PhoneNumber>;
	queryCode?: string;
}

const ChooseNumberPage: React.FC<Props> = () => {
	const { t } = useTranslation();
	const location = useLocation<LocationState>();

	const phoneNumbers = useMemo(
		() => location.state?.phoneNumbers || [],
		[location]
	);
	const queryCode = useMemo(
		() => location.state?.queryCode || "",
		[location]
	);
	// const [selected, setSelected] = useState<Msisdn>("");
	const history = useHistory();

	if (queryCode == null || queryCode == "null" || queryCode == "" || queryCode == undefined) {
		console.log("querycode empty ")
	} else {
		var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?code=' + queryCode;
		window.history.pushState({ path: newurl }, '', newurl);
	}


	const msisdn: MsisdnState = useSelector(
		(state: ApplicationState) => state.msisdn,
		shallowEqual
	);

	const dispatch = useDispatch();

	const [showErrorModal, setShowErrorModal] = React.useState(false);
	const [errorModalMessage, setErrorModalMessage] = React.useState<string>("");

	useEffect(() => {
		if (phoneNumbers.length < 1) {
			setErrorModalMessage(t("chooseNumber.numberNotFound"));
			setShowErrorModal(true);
		}
	}, [phoneNumbers, t]);

	const onClickHandler = () => {
		history.push({
			// pathname: routes.chooseTypeInfo,
			pathname: routes.packageInfo,

			state: {
				hasPrev: true,
				queryCode: queryCode,
			},
		});
		console.log(history.location.pathname);
	};

	// if (location.state?.phoneNumbers === undefined) {
	// 	return <Redirect to={routes.searchPhoneNumber} />;
	// }

	const ErrorModal = () => (
		<Modal show={showErrorModal}>
			<div className="Modal__content PersonalInfo__modal">
				<IconError className="Modal__error-icon" />
				<h2 className="Modal__title">
					{t("chooseNumber.noSuitableNumbersFound")}
				</h2>
				<p className="Modal__description">{errorModalMessage}</p>
				<button
					type="button"
					className="button Modal__button"
					onClick={() => {
						setShowErrorModal(false);
						history.push(routes.searchPhoneNumber);
					}}
				>
					{t("modal.OK")}
				</button>
			</div>
		</Modal>
	);

	return (
		<>
			<PageWrapper>
				<div className="grid">
					<div className="grid__rowProcess">
						<div className="grid__column grid__columnWitoutPadding">
							<ProcessBar step={1} />
						</div>
					</div>
				</div>
				<Container className="Container--align-contents-middle">
					<div className="grid">
						<div className="grid__rowU">
							<div className="grid__columnU grid__alignStart">
								<img
									src={leftImage}
								/>
							</div>
							<div className="grid__columnU4 ">
								<h2 className="NumberSelection__heading heading--center">
									{t("searchNumber.title")}
								</h2>
								<h2 className="NumberSelection__subHeading heading--center">
									{t("searchNumber.subTitle")}
								</h2>
								<div className="NumberSelection__card">
									<h2 className="ChooseNumber__description">
										{t("chooseNumber.description")}
									</h2>
									<div>
										{phoneNumbers.length > 0 ? (
											<div className="ChooseNumber__radio-group">
												{phoneNumbers.map((phoneNumber: PhoneNumber) => {
													return (
														<RadioButton
															key={phoneNumber.MSISDN}
															value={phoneNumber.MSISDN}
															selected={msisdn.number}
															onChange={(selected: string) => {
																dispatch({
																	type: MsisdnActionTypes.SAVE,
																	payload: {
																		number: selected,
																	},
																});
															}}
															className="ChooseNumber__radio"
														>
															{getFormattedMsisdn(phoneNumber.MSISDN)}
														</RadioButton>
													);
												})}
											</div>
										) : (
											<div className="ChooseNumber__text">
												<p>{t("chooseNumber.noNumbersFoundForYourCriteria")}</p>
											</div>
										)}
									</div>
									<div className="ChooseNumber__buttons">
										<Link
											to={{
												pathname: routes.searchPhoneNumber,
											}}
										>
											<button
												type="button"
												className="button button--clear ChooseNumber__button"
											>
												{t("chooseNumber.searchAgain")}
											</button>
										</Link>
										{phoneNumbers.length > 0 ? (
											<button
												type="button"
												className="button ChooseNumber__button"
												disabled={msisdn.number ? false : true}
												onClick={onClickHandler}
											>
												{t("chooseNumber.continue")}
											</button>
										) : null}
									</div>
								</div>

							</div>
							<div className="grid__columnU6 grid__alignEnd">
								<img
									src={rightImage}
								/>
							</div>
						</div>
					</div>

				</Container>
			</PageWrapper>
			<ErrorModal />
		</>
	);
};

export default ChooseNumberPage;
