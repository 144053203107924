import React from "react";

interface Props {
	className?: string;
}

const IconForm: React.FC<Props> = ({ className }) => {
	return (
		<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" width="90" height="90">
			<title>svg+xml;base64,PHN2ZyB3aWR0aD0iMTMxIiBoZWlnaHQ9IjE</title>
			<defs>
				<image width="59" height="70" id="img5" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADsAAABGCAYAAABymeysAAAAAXNSR0IArs4c6QAABKFJREFUeF7tm0Fy2zYUhv9HTTqOlEzcE1Q+QdUTxFmlu1InqDyyO9NV4112sU9g7zoTS1P3BOa2q7AnqHKCqDeQ7VDxZCK+DEjLpigSIEhA4jjSUgTw8OEHHh7AR8I39KNviBUb2Ieq9kbZjbIPYASk03jL/bPdoEfPmbFLQJuBbSLqmORm5hGB/ABXx/AOJybbTreVCdty33YA5wREuzaNL7TNEKCHgdc/t2VzCbbpDt8Q4ciWQVW7zDiaev1jVbkyzxdgH7tnrxxyTso0ZLIOM59Pvf09k22Ktu5go6lLjf9MG4ja46SlZQsMBqXiGxvACdjBO2trtARsNEaGFY5gY6/73QcrqpZUdt4Xk8ARbMsd9kD4q46wJhWOYJvu4JyIfs2FZfzL4NMQPLnBx5Hufthyhz4Iz/Paz1qz6bImFJ4rK+3MjMMXN96BX1Z5E7AmFL5VdviBCO08GNuwM8Y/DcLLIoNZReFY2e5Q+MvcX3DRr3Q6UikrBtMB9aRLKdk7Zj/AdVd3OdUGViwTpe9IAIuYeorrFzrAtYIt5CwrANcO1iZwLWFLABeKpWsLqw8cHk+9A+lpbSWwTffsiMh5k+fuQ+ZXTOEo83lIrx2Hfs56Fm8RtxsFYxLgakfmsGoBW2R/zSrDzCC63xWZuTv19r289lYCayv2XoaVT+WVwG65Z7sNct6VVTA3pl5StgawkbPpDsYE+sEkcC2VjT2r3EmVGYTawsbqDkcE/FgGrJiDqsk0jjrrnmy38PQIhB5Az6pC11rZJFzTHbgAdwDqEGi7ILg4ht6v+9RZjLlOyhYkyiumWvcb2MTIrWSfrSjoXfW1KysCBhBLbzq0YHn2/433+zirztpgVYa1AFOF4zd74V7g/bZwOFDZtLJmhScloosqQKq6zBhPvf7OogeXBya2YD0i+kXV4arP07eaa1FWdVtYFXJeP31ke9CwtVBWNcKmlJ3x552kZ1bZtbJmRYzbpKciF8LokS05SCGHh5+8g9O1O6h5UP8YT3oUx7a5r050VWaEfgj4We+W1qOsLoGh8hvYxEDaWbOGlNJtZq3KiqSTGeGZww2tfCkiHs8Qjm84eK/zYmptsC13cAqiP3TVSZYXISFh1k3HwLU6z5q8B86KgesGK01L0FabZz8VUXct09h0bFw0jWEDa3vrUY2w3jTmy+Biv9DtosqutX3WzFTmS2b0ZG/e6hEbx8lit3e/elqK0gxMGF+8vPsmi3dQg4nshj591NJHM1PDzDRWpNsV9ZZmkPJbUS4dxp4sE71QOl/W2dI2WPY0rpaJd5vOp36dGF9vRnn8IhW6dB6j3iBxW3yIEdehDghSrx3w1ffqnAqbWeR6dKVLM/B+etGXfplyn0nelTup0r1YUUXVHhvNjXlfVJ5uRX0uaYYvA75uq46LC284ld6uZFdsV1OlBM3tL8CKbwUceuTbvDU0Dc7Mf0+9/V6RdpfziO9SAaodzIsYr1ZGL9RcWLNpw1HuEshlIvEdnrGkDwOAPoP9T/h4rlqjaVvFMsTdk+0tPIncOgEdgny/qwa01MWR+BBD/FvlOwWpsmY7XI/WvgIygs90OjSX3gAAAABJRU5ErkJggg==" />
			</defs>
			<style>
			</style>
			<use id="Layer copy 4" href="#img5" transform="matrix(1,0,0,1,19,7)" />
		</svg>
	);
};

export default IconForm;
