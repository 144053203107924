import React from "react";
import "./style.scss";
import IconSpinner from "assets/svg/IconSpinner";

interface Props {
	show: boolean;
}

const SpinnerOverlay: React.FC<Props> = ({ show }) => {
	return (
		<>
			{show ? (
				<div className="SpinnerOverlay">
					<IconSpinner className="SpinnerOverlay__icon" />
				</div>
			) : null}
		</>
	);
};

export default SpinnerOverlay;
