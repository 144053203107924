import React from "react";
import "./style.scss";

interface Props {
  children: React.ReactNode;
}

const Footer: React.FC<Props> = ({ children }) => {
  return <footer className="Footer">{children}</footer>;
};

export default Footer;
