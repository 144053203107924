const routes = {
	root: "/",
	searchPhoneNumber: "/application-form/",
	choosePhoneNumber: "/application-form/choose-number/",
	personalInfo: "/application-form/personal-info",
	addressInfo: "/application-form/address-info",
	chooseTypeInfo: "/application-form/type-info/",
	idInfo: "/application-form/id-info",
	packageInfo: "/application-form/package-info",
	result: "/application-form/result",
	switch:"/application-form/switch",
	switchPersonalInfo:	"/application-form/switch-personal-info",
	mnt:"/application-form/mnt",
	genericResult:"/application-form/generic-result",
	otpValidate:"/application-form/otp-validate"
};

export default routes;
