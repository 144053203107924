import routes from "App/routes";
//import IconError from "assets/svg/IconError";
import { differenceInSeconds, isValid, parse } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ApplicationState } from "store";
import { MsisdnActionTypes, MsisdnState } from "store/msisdn/types";
import Modal from "../Modal";

import "./style.scss";

interface HoursMinsSecs {
	hours?: number;
	minutes?: number;
	seconds?: number;
}

interface Props {
	hoursMinsSecs: HoursMinsSecs;
}

const CountDownTimer: React.FC<Props> = ({ hoursMinsSecs }) => {
	const msisdnInfo: MsisdnState = useSelector(
		(state: ApplicationState) => state.msisdn,
		shallowEqual
	);

	const dispatch = useDispatch();
	const history = useHistory();

	function convertHMS(value: number) {
		const sec = value; // convert value to number if it's string
		let hours = Math.floor(sec / 3600); // get hours
		let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
		let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
		return { hours: hours, minutes: minutes, seconds: seconds }; // Return is HH : MM : SS
	}

	const [timeoutSeconds, setTimeoutSeconds] = useState(0);
	const { hours, minutes, seconds } = convertHMS(timeoutSeconds);
	const [[hrs, mins, secs], setTime] = useState([hours, minutes, seconds]);
	// const [timeIsUp, setTimeIsUp] = useState(false);
	const [showTimeIsUpModal, setShowTimeIsUpModal] = useState(false);

	const { t } = useTranslation();

	useEffect(() => {
		if (msisdnInfo.timeout !== "") {
			const timeoutTime = parse(
				// "13.09.2021 01:20:00", // FOR TESTING
				msisdnInfo.timeout,
				"dd.MM.yyyy HH:mm:ss",
				new Date()
			);

			if (isValid(timeoutTime)) {
				const now = new Date();
				const timeoutSeconds = differenceInSeconds(timeoutTime, now); // - 290;
				// if (timeoutSeconds > 0) {
				// 	setTimeIsUp(false);
				// } else {
				// 	setTimeIsUp(true);
				// }
				setTimeoutSeconds(timeoutSeconds);
				const { hours, minutes, seconds } = convertHMS(timeoutSeconds);
				setTime([hours, minutes, seconds]);
			}
		}
	}, [msisdnInfo]);

	const tick = () => {
		// console.log(msisdnInfo.timeout, timeoutSeconds);
		if (msisdnInfo.timeout !== "" && timeoutSeconds > 0) {
			if (hrs === 0 && mins === 0 && secs === 0) {
				// invalidate msisdn
				dispatch({
					type: MsisdnActionTypes.SAVE,
					payload: {
						number: "",
					},
				});
				// setTimeIsUp(true);
				setShowTimeIsUpModal(true);
			} else if (mins === 0 && secs === 0) {
				setTime([hrs - 1, 59, 59]);
			} else if (secs === 0) {
				setTime([hrs, mins - 1, 59]);
			} else {
				setTime([hrs, mins, secs - 1]);
			}
		} else {
			// DON'T LET USER CONTINUE IF TIMEOUT INFO IS INVALID
			// setTimeIsUp(true);
			// setShowTimeIsUpModal(true);
		}
	};

	// const reset = () => setTime([hours, minutes, seconds]);

	useEffect(() => {
		const timerId = setInterval(() => tick(), 1000);
		return () => clearInterval(timerId);
	});

	const TimeIsUpModal = () => (
		<Modal show={showTimeIsUpModal}>
			<div className="Modal__content">
				{/* <IconError className="Modal__error-icon" /> */}
				<img
					className="CountdownTimer__selocan"
					src="/media/images/selo-uzgunuz.png"
					alt="selo-uzgun"
				/>
				<h2 className="Modal__title">{t("countdownTimer.failTitle")}</h2>
				<p className="Modal__description">{t("countdownTimer.failText")}</p>
				<button
					type="button"
					className="button Modal__button"
					onClick={() => {
						// reset timeout timer state
						dispatch({
							type: MsisdnActionTypes.SAVE,
							payload: {
								timeout: "",
							},
						});
						setShowTimeIsUpModal(false);
						history.push({
							pathname: routes.root,
						});
					}}
				>
					{t("countdownTimer.returnToBeginning")}
				</button>
			</div>
		</Modal>
	);

	// {`${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`}
	if (msisdnInfo.timeout !== "") {
		return (
			<>
				<TimeIsUpModal />
				{hrs < 1 && mins <= 5 ? (
					<div className="CountdownTimer">
						<p className="CountdownTimer__text">{t("countdownTimer.text")}</p>
						<div className="CountdownTimer__timer">{`${mins
							.toString()
							.padStart(2, "0")}:${secs.toString().padStart(2, "0")}`}</div>
					</div>
				) : null}
			</>
		);
	} else {
		return null;
	}
};

export default CountDownTimer;
