import routes from "App/routes";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { MsisdnState } from "store/msisdn/types";
import "./style.scss";

interface Props {
	className?: string;
	step?: any;
	fromPage?: string;
}


const ProcessBar: React.FC<Props> = ({ fromPage, className, step }) => {
	const { t } = useTranslation();

	const existsPage: MsisdnState = useSelector(
		(state: MsisdnState) => state,
		shallowEqual
	);
	useEffect(() => {
		// code to run after render goes here
	});

	const stepList = [{ label: t("chooseNumber.step1"), step: 0 }, { label: t("chooseNumber.step2"), step: 1 },
	{ label: t("chooseNumber.step3"), step: 2 }, { label: t("chooseNumber.step3"), step: 3 }];
	const mntList = [{ label: t("mnt.step1"), step: 0 }, { label: t("mnt.step2"), step: 1 },
	{ label: t("mnt.step3"), step: 2 }, { label: t("mnt.step3"), step: 3 }];

	return (
		<div className={"ProcessBar" + (className ? " " + className : "")}>
			{fromPage === routes.mnt ?
				<div className="ProcessBar__items">
					<div className="ProcessBar__item">
						<div className={"ProcessBar__line " + (step > 0 ? " ProcessBar__fillOk" : "")}></div>
						<div className={"ProcessBar__item-text"}>
							{t("mnt.step1")}
						</div>
					</div>

					<div className="ProcessBar__item">
						<div className={"ProcessBar__line " + (step > 1 ? " ProcessBar__fillOk" : "")}></div>
						<div className="ProcessBar__item-text">
							{t("mnt.step2")}
						</div>
					</div>
					<div className="ProcessBar__item">
						<div className={"ProcessBar__line " + (step > 2 ? " ProcessBar__fillOk" : "")}></div>
						<div className="ProcessBar__item-text">
							{t("mnt.step3")}
						</div>
					</div>
					{/* <div className="ProcessBar__item">
						<div className={"ProcessBar__line " + (step > 2 ? " ProcessBar__fillOk" : "")}></div>
						<div className="ProcessBar__item-text">
							{t("mnt.step4")}
						</div>
					</div> */}
					<div className="ProcessBar__item">
						<div className={"ProcessBar__line " + (step > 3 ? " ProcessBar__fillOk" : "")}></div>
						<div className="ProcessBar__item-text">
							{t("mnt.step5")}
						</div>
					</div>
				</div> : ""}

			{fromPage === routes.switch ?
				<div className="ProcessBar__items">
					<div className="ProcessBar__item">
						<div className={"ProcessBar__line " + (step > 0 ? " ProcessBar__fillOk" : "")}></div>
						<div className="ProcessBar__item-text">
							{t("mnt.step1")}
						</div>
					</div>

					<div className="ProcessBar__item">
						<div className={"ProcessBar__line " + (step > 1 ? " ProcessBar__fillOk" : "")}></div>
						<div className="ProcessBar__item-text">
							{t("mnt.step2")}
						</div>
					</div>

					<div className="ProcessBar__item">
						<div className={"ProcessBar__line " + (step > 2 ? " ProcessBar__fillOk" : "")}></div>
						<div className="ProcessBar__item-text">
							{t("mnt.step3")}
						</div>
					</div>

					<div className="ProcessBar__item">
						<div className={"ProcessBar__line " + (step > 3 ? " ProcessBar__fillOk" : "")}></div>
						<div className="ProcessBar__item-text">
							{t("mnt.step5")}
						</div>
					</div>
				</div> : ""}

			{(fromPage == undefined || fromPage == null || fromPage.length == 0) ?
				<div className="ProcessBar__items">
					<div className={"ProcessBar__item" + (step >= 2 ? " ProcessBar__itemOk" : "")}>
						<div className={"ProcessBar__line " + (step > 1 ? " ProcessBar__fillOk" : "")}></div>
						<div className="ProcessBar__item-text">
							{t("chooseNumber.step1")}
						</div>
					</div>
					<div className="ProcessBar__item">
						<div className={"ProcessBar__line " + (step > 2 ? " ProcessBar__fillOk" : "")}></div>
						<div className="ProcessBar__item-text">
							{t("mnt.step2")}
						</div>
					</div>

					<div className="ProcessBar__item">
						<div className={"ProcessBar__line " + (step > 2 ? " ProcessBar__fillOk" : "")}></div>
						<div className="ProcessBar__item-text">
							{t("mnt.step3")}
						</div>
					</div>

					<div className="ProcessBar__item">
						<div className={"ProcessBar__line " + (step > 3 ? " ProcessBar__fillOk" : "")}></div>
						<div className="ProcessBar__item-text">
							{t("mnt.step4")}
						</div>
					</div>
					<div className="ProcessBar__item">
						<div className={"ProcessBar__line " + (step > 4 ? " ProcessBar__fillOk" : "")}></div>
						<div className="ProcessBar__item-text">
							{t("mnt.step5")}
						</div>
					</div>

				</div> : ""}


			{/* <div className="ProcessBar__items">
				{(fromPage == undefined || fromPage == null) ?
					<div className="ProcessBar__item ">
						<div className={"ProcessBar__line " + (step > 0 ? " ProcessBar__fillOk" : "")}></div>
						<div className="ProcessBar__item-text">
							{t("chooseNumber.step1")}
						</div>
					</div>
					: <div className="ProcessBar__item ">
						<div className={"ProcessBar__line " + (step > 0 ? " ProcessBar__fillOk" : "")}></div>
						<div className="ProcessBar__item-text">
							{t("mnt.step1")}
						</div>
					</div>}
				{fromPage === routes.mnt ? "" :
					<div className="ProcessBar__item">
						<div className={"ProcessBar__line " + (step > 1 ? " ProcessBar__fillOk" : "")}></div>
						<div className="ProcessBar__item-text">
							{t("mnt.step2")}
						</div>
					</div>
				}

				<div className="ProcessBar__item">
					<div className={"ProcessBar__line " + (step > 2 ? " ProcessBar__fillOk" : "")}></div>
					<div className="ProcessBar__item-text">
						{t("mnt.step3")}
					</div>
				</div>

				{fromPage === routes.mnt ?
					<div className="ProcessBar__item">
						<div className={"ProcessBar__line " + (step > 2 ? " ProcessBar__fillOk" : "")}></div>
						<div className="ProcessBar__item-text">
							{t("mnt.step4")}
						</div>
					</div> : ""}



				<div className="ProcessBar__item">
					<div className={"ProcessBar__line " + (step > 3 ? " ProcessBar__fillOk" : "")}></div>
					<div className="ProcessBar__item-text">
						{t("mnt.step5")}
					</div>
				</div>
			</div> */}
		</div>
	);
};

export default ProcessBar;
