export interface PackageInfo {
	packageId: string;
	name: string;
	payload: string;
	campId: string;
	price: string;
}

export enum PackageInfoActionTypes {
	SAVE_PACKAGE_INFO = "@@packageInfo/SAVE",
	DELETE = "@@packageInfo/DELETE",
}

export type PackageInfoState = {
	readonly packageId: string;
	readonly name: string;
	readonly price: string;
	readonly payload: string;
	readonly campId: string;
};
