import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import Landing from "App/pages/Landing";
import AddressInfo from "App/pages/ApplicationForm/AddressInfo";
import ChoosePhoneNumber from "App/pages/ApplicationForm/ChoosePhoneNumber";
import Result from "App/pages/ApplicationForm/Result";
import IdInfo from "App/pages/ApplicationForm/IdInfo";
import SearchPhoneNumber from "App/pages/ApplicationForm/SearchPhoneNumber";
import PackageInfo from "App/pages/ApplicationForm/PackageInfo";
import PersonalInfo from "App/pages/ApplicationForm/PersonalInfo";
import Error404 from "App/pages/Error/404";
import routes from "App/routes";
import SpinnerOverlay from "./components/SpinnerOverlay";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { LoadingSpinnerState } from "store/loadingSpinner/types";
import CountDownTimer from "./components/CountdownTimer";
import ChooseTypeInfo from "./pages/ApplicationForm/ChooseTypeInfo";
import SwitchInfo from "./pages/ApplicationForm/SwitchGetNum";
import SwitchPersonalInfoPage from "./pages/ApplicationForm/PersonalInfoS";
import GenericResultPage from "./pages/ApplicationForm/GenericResult";
import OTPValidate from "./pages/ApplicationForm/OTPValidate";
import MNTInfo from "./pages/ApplicationForm/MNTGetNum";

const App: React.FC = () => {
	const loadingSpinnerStatus: LoadingSpinnerState = useSelector(
		(state: ApplicationState) => state.loadingSpinner,
		shallowEqual
	);


	return (
		<React.Suspense fallback={<SpinnerOverlay show={true} />}>
			<main>
				<Switch>
					<Route exact path="/">
						<Landing />
					</Route>
					<Route exact path={routes.searchPhoneNumber}>
						<SearchPhoneNumber />
					</Route>
					<Route exact path={routes.choosePhoneNumber}>
						<ChoosePhoneNumber />
					</Route>
					<Route exact path={routes.chooseTypeInfo}>
						<ChooseTypeInfo />
					</Route>
					<Route exact path={routes.personalInfo}>
						<PersonalInfo />
					</Route>
					<Route exact path={routes.packageInfo}>
						<PackageInfo />
					</Route>
					<Route exact path={routes.idInfo}>
						<IdInfo />
					</Route>
					<Route exact path={routes.addressInfo}>
						<AddressInfo />
					</Route>
					<Route exact path={routes.result}>
						<Result />
					</Route>
					<Route exact path={routes.switch}>
						<SwitchInfo />
					</Route>
					<Route exact path={routes.mnt}>
						<MNTInfo />
					</Route>
					<Route exact path={routes.switchPersonalInfo}>
						<SwitchPersonalInfoPage />
					</Route>
					<Route exact path={routes.genericResult}>
						<GenericResultPage />
					</Route>
					<Route exact path={routes.otpValidate}>
						<OTPValidate />
					</Route>
					<Route path="*">
						<Error404 />
					</Route>
				</Switch>
			</main>
			<SpinnerOverlay show={loadingSpinnerStatus.show} />

		</React.Suspense>
	);
};

export default App;
