export enum ResultActionTypes {
	SAVE = "@@result/SAVE",
	DELETE = "@@result/DELETE",
}

export type ResultState = {
	readonly msisdn: string;
	readonly confirmationCode: string;
	readonly status: string;
	readonly clickNo: string;
};
