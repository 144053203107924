import React from "react";

interface Props {
	className?: string;
}

const IconSim: React.FC<Props> = ({ className }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 195 195"
			version="1.1"
			focusable="false"
			className={className ? className : ""}
		>
			<defs>
				<filter
					colorInterpolationFilters="sRGB"
					id="iconsimshadowfilter1046"
					x="-0.108"
					width="1.21"
					y="-0.108"
					height="1.28"
				>
					<feFlood
						floodOpacity="0.498039"
						floodColor="rgb(0,0,0)"
						result="flood"
					/>
					<feComposite
						in="flood"
						in2="SourceGraphic"
						operator="in"
						result="composite1"
					/>
					<feGaussianBlur
						in="composite1"
						stdDeviation="6.84"
						result="blur"
					/>
					<feOffset dx="0" dy="14" result="offset" />
					<feComposite
						in="SourceGraphic"
						in2="offset"
						operator="over"
						result="composite2"
					/>
				</filter>
			</defs>
			<g transform="translate(16.416,16.416)">
				<circle
					fill="#0c4ba3"
					filter="url(#iconsimshadowfilter1046)"
					cx="76"
					cy="76"
					r="76"
				/>
				<g transform="translate(-1.9998893,4.0000166)">
					<path
						fill="#ffffff"
						d="m 264.56641,147.40234 -56.69141,56.69141 -0.002,-0.002 v 159.64258 c 0,18.34618 14.771,33.11523 33.11718,33.11523 h 107.62696 c 18.34618,0 33.11523,-14.76905 33.11523,-33.11523 v -183.2168 c 0,-18.34619 -14.76905,-33.11524 -33.11523,-33.11524 z"
						transform="scale(0.26458333)"
					/>
					<g id="g1269">
						<rect
							fill="#ffc900"
							width="31"
							height="36"
							x="62.499893"
							y="60.533119"
							rx="3"
						/>
						<path
							fill="#0c4ba3"
							d="m 289.13281,260.91211 v 30.23633 h -53.38672 v 9.07031 h 24.09571 v 30.23633 h 11.33984 v -30.23633 h 47.24219 v 30.23633 h 11.33984 v -30.23633 h 23.14844 v -9.07031 h -52.43945 v -30.23633 z"
							transform="scale(0.26458333)"
						/>
						<rect
							fill="#0c4ba3"
							width="3"
							height="12"
							x="76.499893"
							y="84.533119"
						/>
						<rect
							fill="#0c4ba3"
							width="3"
							height="12"
							x="84.249886"
							y="60.533119"
						/>
						<rect
							fill="#0c4ba3"
							width="3"
							height="12"
							x="68.749893"
							y="60.533119"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default IconSim;
