import "core-js/stable";
import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import reportWebVitals from "reportWebVitals";
import "./i18n";

import App from "App";
import RecaptchaProvider from "App/components/ReCaptchaV3";

import "assets/styles/theme.scss";
import { persistor, store } from "store";
import { PersistGate } from "redux-persist/integration/react";
import ScrollToTop from "App/components/ScrollToTop";

const rootElement = document.getElementById("root");
render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<RecaptchaProvider
				siteKey={process.env.REACT_APP_GRECAPTCHA_SITE_KEY}
			>
				<BrowserRouter>
					<ScrollToTop />
					<App />
				</BrowserRouter>
			</RecaptchaProvider>
		</PersistGate>
	</Provider>,
	rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
