import Container from "App/components/Container";
import PageWrapper from "App/components/PageWrapper";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import "./style.scss";

const Error404 = () => {
	const { t } = useTranslation();
	return (
		<PageWrapper>
			<Container className="Container--align-contents-middle">
				<div className="grid">
					<div className="grid__row">
						<div className="grid__column">
							<h2 className="heading heading--navy heading--center">{t("error404.title")}</h2>
							<p className="heading--center">
								<Trans i18nKey="error404.text">
									Aradığınız sayfa burada değil. Anasayfaya dönmek için{" "}
									<a href="/" className="link link--navy">
										tıklayın
									</a>
									.
								</Trans>
							</p>
						</div>
					</div>
				</div>
			</Container>
		</PageWrapper>
	);
};

export default Error404;
