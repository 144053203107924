import React from "react";

interface Props {
	className?: string;
}

const IconPhone: React.FC<Props> = ({ className }) => {
	return (
		<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="phone-call">
				<path id="&#240;&#159;&#142;&#168; Icon &#208;&#161;olor" fill-rule="evenodd" clip-rule="evenodd" d="M22.7549 17.4265C22.7549 17.1675 22.7329 16.9125 22.6909 16.6645C22.6509 16.4145 22.5879 16.1695 22.5059 15.9345C22.3939 15.6095 22.1209 15.3645 21.7849 15.2875L15.8209 13.9185C15.4919 13.8455 15.1489 13.9385 14.9049 14.1715C14.7629 14.3085 14.7599 14.3125 14.1089 15.5535C11.9789 14.5825 10.2339 12.8445 9.22988 10.6695C10.4699 10.0215 10.4729 10.0185 10.6089 9.87552C10.8429 9.63252 10.9379 9.28752 10.8619 8.95952L9.49288 2.99552C9.41588 2.65952 9.17188 2.38752 8.84588 2.27452C8.60888 2.19252 8.36388 2.13052 8.12088 2.09152C7.87188 2.04852 7.61488 2.02652 7.35488 2.02652C4.81788 2.02652 2.75488 4.08952 2.75488 6.62552C2.75488 15.1175 9.66388 22.0265 18.1549 22.0265C20.6919 22.0265 22.7549 19.9625 22.7549 17.4265ZM20.7339 17.0985C20.7479 17.2055 20.7549 17.3155 20.7549 17.4265C20.7549 18.8605 19.5889 20.0265 18.1549 20.0265C10.7659 20.0265 4.75488 14.0145 4.75488 6.62552C4.75488 5.19252 5.92188 4.02652 7.35488 4.02652C7.46388 4.02652 7.57188 4.03252 7.68288 4.04752L8.74288 8.66752C8.56988 8.75852 8.38088 8.85752 8.20588 8.94852C7.34388 9.39952 6.66388 9.75552 7.02088 10.5395C8.19588 13.8865 10.8949 16.5855 14.2009 17.7455C15.0432 18.083 15.37 17.4581 15.8219 16.594L15.8279 16.5825C15.9219 16.4055 16.0219 16.2135 16.1129 16.0375L20.7339 17.0985ZM13.7549 4.02652C17.6139 4.02652 20.7549 7.16652 20.7549 11.0265C20.7549 11.5785 21.2019 12.0265 21.7549 12.0265C22.3079 12.0265 22.7549 11.5785 22.7549 11.0265C22.7549 6.06352 18.7179 2.02652 13.7549 2.02652C13.2029 2.02652 12.7549 2.47352 12.7549 3.02652C12.7549 3.57852 13.2029 4.02652 13.7549 4.02652ZM13.7549 6.02652C13.2029 6.02652 12.7549 6.47352 12.7549 7.02652C12.7549 7.57852 13.2029 8.02652 13.7549 8.02652C15.4089 8.02652 16.7549 9.37152 16.7549 11.0265C16.7549 11.5785 17.2019 12.0265 17.7549 12.0265C18.3079 12.0265 18.7549 11.5785 18.7549 11.0265C18.7549 8.26952 16.5119 6.02652 13.7549 6.02652Z" fill="#253342" />
			</g>
		</svg>


	);
};

export default IconPhone;
