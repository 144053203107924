import React from "react";

interface Props {
	className?: string;
}

const IconSuccess: React.FC<Props> = ({ className }) => {
	return (
		<svg
			viewBox="0 0 1024 1024"
			xmlns="http://www.w3.org/2000/svg"
			className={className ? className : ""}
		>
			<path
				fill="currentColor"
				d="M512 0c-282.761 0-512 229.239-512 512s229.239 512 512 512 512-229.239 512-512-229.239-512-512-512zM731.682 239.807l106.373 106.373-437.999 437.999-214.125-214.18 105.69-105.677 107.752 107.807 332.322-332.322z"
			/>
		</svg>
	);
};

export default IconSuccess;
