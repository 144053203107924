import React from "react";
import "./style.scss";

interface Props {
	children: React.ReactNode;
	className?: string;
}

const Container: React.FC<Props> = ({ children, className }) => {
	return (
		<div className={"Container " + (className ? className : "")}>
			{children}
		</div>
	);
};

export default Container;
