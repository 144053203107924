import React from "react";
import logo from "assets/images/logo.png";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import LanguageSelection from "App/components/LanguageSelection";
import CountDownTimer from "../CountdownTimer";
// import IconArrowCircleLeft from "assets/svg/IconArrowCircleLeft";

const Header = () => {
	const { t } = useTranslation();

	const path = useLocation().pathname;
	const location = path.split("/")[1];
	const revDiv = () => {

	}


	return (
		<div className={"Header" + (location ? "__line" : "")}>
			<div className="grid__row" >
				<div className="grid__column grid__columnWitoutPadding">
					<div className="Header__heading heading heading--navy heading--center">
						<a href="https://hizlihat.kktcell.com/">
							<img className="Header__logo" src={logo} alt="KKTCELL" />
						</a>
					</div>
					<h2 hidden={(location ? true : false)} className="Header__mainHeading heading--white heading--center">
						{t("header.title")}
					</h2>
					<p hidden={(location ? true : false)} className="Header__subHeading text text--center">
						{t("header.text")}
					</p>
				</div>
			</div>
			{/* <LanguageSelection /> */}
			<CountDownTimer
				hoursMinsSecs={{ hours: 0, minutes: 0, seconds: 10 }}
			/>
		</div>


	);
};

export default Header;
