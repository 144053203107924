import Card from "App/components/Card";
import React, { ReactNode } from "react";
import "./style.scss";

interface Props {
	show: boolean;
	children: ReactNode;
	className?: string;
}

const Modal: React.FC<Props> = ({ show, children, className }) => {
	return (
		<>
			{show ? (
				<div className={"Modal" + (className ? ` ${className}` : "")}>
					<div className="Modal__container">
						<Card className="Modal__card">{children}</Card>
					</div>
				</div>
			) : null}
		</>
	);
};

export default Modal;
