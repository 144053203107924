export enum MsisdnActionTypes {
	SAVE = "@@msisdn/SAVE",
	DELETE = "@@msisdn/DELETE",
}

export type MsisdnState = {
	readonly number: string;
	readonly timeout: string;
	readonly otpStatus: string;
	readonly otpSession: string;
	readonly otpValidatedStatus: boolean;
	readonly returnPage: string;
};
