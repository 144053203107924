import axios from "axios";
import React, { ReactNode, useEffect } from "react";

interface Props {
	siteKey: string | undefined;
	children: ReactNode;
}

const RecaptchaProvider: React.FC<Props> = ({ siteKey, children }) => {
	useEffect(() => {
		const SITE_KEY = siteKey;
		const loadScriptByURL = (id: string, callback: CallableFunction) => {
			const isScriptExist = document.getElementById(id);

			if (!isScriptExist) {
				var script = document.createElement("script");
				script.type = "text/javascript";
				script.src = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;
				script.id = id;
				script.onload = function () {
					if (callback) callback();
				};
				document.body.appendChild(script);
			}

			if (isScriptExist && callback) callback();
		};

		// load the script by passing the URL
		loadScriptByURL("recaptcha-key", function () {
			if (process.env.NODE_ENV !== "production") {
				console.log("Recaptcha V3 Script loaded!");
			}
		});
	}, [siteKey]);

	return <>{children}</>;
};

export const getRecaptchaScore = async (siteKey: string | undefined) => {
	const recaptchaVerify = async (response: string) => {
		const url = `/recaptcha-verify`;
		const apiKey = process.env.REACT_APP_BASE_API_KEY;
		return await axios
			.post(
				url,
				{
					token: response,
				},
				{
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						"x-api-key": apiKey,
					},
				}
			)
			.then((response) => {
				if (process.env.NODE_ENV !== "production") {
					console.log(
						`Recaptcha score is ${response?.data?.data?.score}`
					);
				}

				return response.data.data.score;
			})
			.catch((error) => {
				if (process.env.NODE_ENV !== "production") {
					//console.log(error);
				}
				console.error("Error getting recaptcha score.");
				return 0.0;
			});
	};

	try {
		return await (window as any).grecaptcha
			.execute(siteKey, {
				action: "submit",
			})
			.then(async function (token: string) {
				return await recaptchaVerify(token);
			});
	} catch (e) {
		return null;
	}
};

export default RecaptchaProvider;
