import { Reducer } from "redux";
import { PackageInfoState, PackageInfoActionTypes } from "./types";

const initialState: PackageInfoState = {
	packageId: "",
	name: "",
	payload: "",
	campId: "",
	price: "",
};

const reducer: Reducer<PackageInfoState> = (state = initialState, action) => {
	switch (action.type) {
		case PackageInfoActionTypes.SAVE_PACKAGE_INFO:
			console.log("ACTION PAYLOAD:", action.payload);
			return { ...state, ...action.payload };
		case PackageInfoActionTypes.DELETE:
			return { ...state, ...initialState };
		default: {
			return state;
		}
	}
};

export default reducer;
