import React from "react";

import Footer from "App/components/Footer";
import Header from "App/components/Header";
import "./style.scss";
import { useTranslation } from "react-i18next";

interface Props {
	children: React.ReactNode;
	className?: string;
}

const PageWrapper: React.FC<Props> = ({ children, className }) => {
	const { t } = useTranslation();
	return (
		<div className="PageWrapper">
			<Header />

			<div className={"Page " + (className ? className : "")}>
				{children}
			</div>
			<Footer>
				<div className="Footer__link">
					<a
						className="link"
						rel="noreferrer"
						target="_blank"
						// href={t("footer.aboutGettingNewNumberUrl")}
					>
						{t("footer.aboutGettingNewNumber")}
					</a>
				</div>
				<div className="Footer__link-separator">&nbsp;&nbsp;</div>
				<div className="Footer__link">

					<div className="Footer__link">
						<a
							className="link"
							rel="noreferrer"
							target="_blank"
							href={t("footer.SSSLink")}
						>
							{t("footer.SSS")}
						</a>

						<a
							className="link"
							rel="noreferrer"
							target="_blank"
							href={t("footer.aboutSwitchingToPostpaidUrl")}
						>
							{t("footer.aboutSwitchingToPostpaid")}
						</a>
					</div>
				</div>
				{/* 
        <div className="Footer__link-separator">
					&nbsp;-&nbsp;
        </div>
        <div className="Footer__link">
					<a
						className="link"
						rel="noreferrer"
						target="_blank"
						href="//www.kktcell.com/"
					>{t("footer.abouttTransferringNumberToTurkcell")}
          </a>
        </div>
        */}
			</Footer>
		</div>
	);
};

export default PageWrapper;
