import Container from "App/components/Container";
import Modal from "App/components/Modal";
import PageWrapper from "App/components/PageWrapper";
import RadioButton from "App/components/RadioButton";
import { getFormattedMsisdn } from "App/helpers/functions";
import routes from "App/routes";
import IconError from "assets/svg/IconError";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { ApplicationState } from "store";
import { MsisdnActionTypes, MsisdnState } from "store/msisdn/types";
import { PhoneNumber } from "type";
import "./style.scss";
import leftImage from "assets/images/fikriye.png";
import rightImage from "assets/images/gozluklu.png";
interface Props { }

interface LocationState {
	from: {
		pathname: string;
	};
	hasPrev?: boolean;
	queryCode?: string;
	phoneNumber?: string;
}

const ChooseTypeInfo: React.FC<Props> = () => {
	const { t } = useTranslation();
	const location = useLocation<LocationState>();

	const queryCode = useMemo(
		() => location.state?.queryCode || "",
		[location]
	);
	const [selected, setSelected] = useState("");
	const history = useHistory();

	if (queryCode == null || queryCode == "null" || queryCode == "" || queryCode == undefined) {
		console.log("querycode empty ")
	} else {
		var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?code=' + queryCode;
		window.history.pushState({ path: newurl }, '', newurl);
	}


	const msisdn: MsisdnState = useSelector(
		(state: ApplicationState) => state.msisdn,
		shallowEqual
	);

	const dispatch = useDispatch();

	const [showErrorModal, setShowErrorModal] = React.useState(false);
	const [errorModalMessage, setErrorModalMessage] = React.useState<string>("");

	const onClickHandler = () => {
		history.push({
			pathname: routes.chooseTypeInfo,
			state: {
				hasPrev: true,
				queryCode: queryCode,
			},
		});
		console.log(history.location.pathname);
	};

	// if (location.state?.hasPrev !== true) {
	// 	return <Redirect to={routes.searchPhoneNumber} />;
	// }

	const ErrorModal = () => (
		<Modal show={showErrorModal}>
			<div className="Modal__content PersonalInfo__modal">
				<IconError className="Modal__error-icon" />
				<h2 className="Modal__title">
					{t("chooseNumber.noSuitableNumbersFound")}
				</h2>
				<p className="Modal__description">{errorModalMessage}</p>
				<button
					type="button"
					className="button Modal__button"
					onClick={() => {
						setShowErrorModal(false);
						history.push(routes.searchPhoneNumber);
					}}
				>
					{t("modal.OK")}
				</button>
			</div>
		</Modal>
	);

	return (
		<>
			<PageWrapper>
				<Container className="Container--align-contents-middle">
					<div className="grid">
						<div className="grid__rowU">
							<div className="grid__columnU grid__alignStart">
								<img
									src={leftImage}
								/>
							</div>
							<div className="grid__columnU4">
								<h2 className="NumberSelection__heading heading--center">
									{t("searchNumber.title")}
								</h2>
								<h2 className="NumberSelection__subHeading heading--center">
									{t("searchNumber.subTitle")}
								</h2>
								<div className="NumberSelection__card">
									<h2 className="ChooseNumber__description">
										{t("chooseType.description")}
									</h2>

									<div className="ChooseTypeInfo__radio-group">
										<RadioButton
											value="1"
											selected={selected}
											onChange={setSelected}
											className="ChooseTypeInfo__radio"
										>
											<div>{t("chooseType.selectionOne")}</div>

										</RadioButton>
										<RadioButton
											value="2"
											selected={selected}
											onChange={setSelected}
											className="ChooseTypeInfo__radio"
										>
											<div>{t("chooseType.selectionTwo")}</div>
										</RadioButton>
									</div>

									<div className="ChooseNumber__buttons">

										<Link
											to={{
												pathname: routes.searchPhoneNumber,
											}}
										>
											<button
												type="button"
												className="button button--clear ChooseNumber__button"
											>
												{t("chooseType.searchAgain")}
											</button>
										</Link>
										
										{msisdn.number !== null ? (
											<button
												type="button"
												className="button ChooseNumber__button"
												disabled={msisdn.number ? false : true}
												onClick={onClickHandler}
											> {t("chooseNumber.continue")}
											</button>
										) : null}
									</div>
								</div>

							</div>
							<div className="grid__columnU6 grid__alignEnd">
								<img
									src={rightImage}
								/>
							</div>
						</div>
					</div>

				</Container>
			</PageWrapper>
			<ErrorModal />
		</>
	);
};

export default ChooseTypeInfo;
