import Container from "App/components/Container";
import PageWrapper from "App/components/PageWrapper";
import routes from "App/routes";
import leftImage from "assets/images/fikriye.png";
import rightImage from "assets/images/gozluklu.png";


import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import "./style.scss";
import Modal from "App/components/Modal";
import IconError from "assets/svg/IconError";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { LoadingSpinnerActionTypes } from "store/loadingSpinner/types";
import { MsisdnActionTypes, MsisdnState } from "store/msisdn/types";
import { ApplicationState } from "store";
import { apiEndpoints } from "App/api/endpoints";
import api from "App/api";
import ProcessBar from "App/components/ProcessBar";
import OtpInput from 'react-otp-input';
import OTPTimer from "App/components/OTPTimer";

interface Props {
	type?: string;
}
const OTPValidate: React.FC<Props> = ({ type }) => {
	const { t } = useTranslation();
	let otp = "";

	const [msisdn, setMsisdn] = useState("");
	const [OTP, setOTP] = useState(otp);


	const [showErrorModal, setShowErrorModal] = React.useState(false);
	const [errorModalMessage, setErrorModalMessage] =
		React.useState<string>("");
	const history = useHistory();

	function useQuery() {
		const { search } = useLocation();

		return React.useMemo(() => new URLSearchParams(search), [search]);
	}

	const existMsisdn: MsisdnState = useSelector(
		(state: ApplicationState) => state.msisdn,
		shallowEqual
	);

	let query = useQuery();
	const queryCode = query.get("code")

	const dispatch = useDispatch();

	if (existMsisdn.otpStatus !== "0") {
		return <Redirect to={routes.root} />;
	}

	// function handleSetOTP(index: any, value: any) {
	// 	const nextCounters = OTP.map((c, i) => {
	// 		if (i === index) {
	// 			return value;
	// 		} else {
	// 			return c;
	// 		}
	// 	});
	// 	if (index < 4) {
	// 		console.log("FF" + document.getElementById("numberIndex" + (index + 1)));
	// 		document.getElementById("numberIndex" + (index + 1))?.focus();
	// 	}
	// 	setOTP(nextCounters);
	// }

	const handleOnClick = async () => {
		dispatch({
			type: LoadingSpinnerActionTypes.SHOW_LOADING_SPINNER,
		});
		console.log("OTP.toString() " + OTP.toString().replaceAll(",", ""));
		const apiEndpoint = apiEndpoints.checkOTP;
		console.log(apiEndpoint);
 
 
 

		api
			.post(apiEndpoint, {
				msisdn: existMsisdn.number,
				password: OTP.toString().replaceAll(",", "")
			})
			.then((response) => {
				const data = response.data;
				console.log("data : " + JSON.stringify(response));

				if (data.resultCode === 0) {
					// if (existMsisdn.returnPage === routes.mnt) {
					// 	history.push({
					// 		pathname: routes.personalInfo,
					// 		state: {
					// 			hasPrev: true,
					// 			queryCode: queryCode,
					// 			operation: true
					// 		},
					// 	});
					// }
					// else {
					// 	history.push({
					// 		pathname: routes.packageInfo,
					// 		state: {
					// 			hasPrev: true,
					// 			queryCode: queryCode,
					// 			operation: true
					// 		},
					// 	});
					// }

					history.push({
						pathname: routes.packageInfo,
						state: {
							hasPrev: true,
							queryCode: queryCode,
							operation: true
						},
					});

				} else {
					setErrorModalMessage(t("switchNumber.otpPasswordHata"));
					setShowErrorModal(true);
				}
			})
			.catch((e) => {
				setErrorModalMessage(t("switchNumber.otpPasswordHata"));
				setShowErrorModal(true);

			})
			.then(() => {
				dispatch({
					type: LoadingSpinnerActionTypes.HIDE_LOADING_SPINNER,
				});
			});
	};

	const ErrorModal = () => (
		<Modal show={showErrorModal}>
			<div className="Modal__content PersonalInfo__modal">
				<IconError className="Modal__error-icon" />
				<h2 className="Modal__title">{t("modal.failTitle")}</h2>
				<p className="Modal__description">{errorModalMessage}</p>
				<button
					type="button"
					className="button Modal__button"
					onClick={() => {
						setShowErrorModal(false);
					}}
				>
					{t("modal.OK")}
				</button>
			</div>
		</Modal>
	);


	const time = new Date();
	time.setSeconds(time.getSeconds() + 120);

	return (
		<>
			<PageWrapper className="interior">
				<div className="grid">
					<div className="grid__rowProcess">
						<div className="grid__column grid__columnWitoutPadding">
							<ProcessBar fromPage={existMsisdn.returnPage} step={1} />
						</div>
					</div>
				</div>
				<Container className="Container--align-contents-middle">
					<div className="grid">
						<div className="grid__rowU">
							<div className="grid__columnU grid__alignStart">
								<img
									src={leftImage}
								/>

							</div>

							<div className="grid__columnU4">
								<h2 className="heading heading--navy heading--center">
									{t("otpValidate.title")}

								</h2>
								<h2 className="SwitchNumberSelection__subHeading heading--center">
									{existMsisdn.number} {t("otpValidate.subTitle")}
									{type}
								</h2>

								<div className="SwitchNumberSelection__card">
									<div className="SwitchNumberSelection__text">

									</div>
									<div className="SwitchNumberSelection__radio-group">

										<div className="OTPValidate__form-input-container">

											<OtpInput inputStyle={{
												width: 56, height: 56, backgroundColor: "#FFFFFF",
												border: "0.4px solid #C4C4C4", marginLeft: 15
											}
											}
												value={OTP}
												onChange={(value: any) => { setOTP(value) }}
												numInputs={4}
												separator={<span> </span>}
											/>

											{/* {OTP.map((counter, i) => (
												<div className="grid__column">
													<NumberFormat
														key={i}
														id={"numberIndex" + i}
														format="#"
														allowEmptyFormatting
														mask=""
														className="OTPValidate__text form__input OTPValidate__input"
														onValueChange={(values) => {
															const { value } = values;
															handleSetOTP(i, value ? Number(value) : Number(-1));
														}}
													/>
												</div>
											))} */}

											{/* <NumberFormat
												format="#"
												allowEmptyFormatting
												mask=""
												className="form__input"
												// value={OTP[1]}
												onValueChange={(values) => {
													const { value } = values;
													handleSetOTP(0, Number(value));
												}}
											/>
											<NumberFormat
												format="#"
												allowEmptyFormatting
												mask=""
												className="form__input"
												// value={OTP[2]}
												onValueChange={(values) => {
													const { value } = values;
													handleSetOTP(1, Number(value));
												}}
											/>
											<NumberFormat
												format="#"
												allowEmptyFormatting
												mask=""
												className="form__input"
												// value={OTP[3]}
												onValueChange={(values) => {
													const { value } = values;
													handleSetOTP(2, Number(value));
												}}
											/>
											<NumberFormat
												format="#"
												allowEmptyFormatting
												mask=""
												className="form__input"
												// value={OTP[4]}
												onValueChange={(values) => {
													const { value } = values;
													handleSetOTP(3, Number(value));
												}}
											/> */}
										</div>

										{/* 										
										<div className="form__group">
													<TextField id="contactNumber" name="contactNumber" label={t("personalInfo.labelContactNumber")} variant="outlined"
														autoCapitalize="words" type="text"
														defaultValue={defaultFormValues.contactNumber} onChange={handleChange} fullWidth />
												</div> */}
									</div>
									<div className="OTPValidate__buttons">
										<button
											type="button"
											className="button"
											onClick={handleOnClick}
										>
											{t("otpValidate.buttonSearch")}
										</button>

									</div>
									<OTPTimer expiryTimestamp={time} expireMethod={setShowErrorModal} />
									<div className="NumberSelection__buttons">
									</div>

								</div>
							</div>
							<div className="grid__columnU6 grid__alignEnd">
								<img
									src={rightImage}
								/>
							</div>
						</div>
					</div>
				</Container>
			</PageWrapper>
			<ErrorModal />
		</>
	);
};

export default OTPValidate;
