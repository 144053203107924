import React from "react";
import "./style.scss";

interface Props {
	children: React.ReactNode;
	className?: string;
}

const Card: React.FC<Props> = ({ children, className }) => {
	return (
		<div className={"Card " + (className ? className : "")}>{children}</div>
	);
};

export default Card;
