import "./style.scss";
import { useTimer } from 'react-timer-hook';

function OTPTimer({ expiryTimestamp }: any,  { expireMethod }:any) {
	const {
		seconds,
		minutes,
		hours,
		days,
		isRunning,
		start,
		pause,
		resume,
		restart,
	} = useTimer({ expiryTimestamp, onExpire: () => expireMethod });

	return (
		<div style={{ textAlign: 'center' }}>
			<div style={{
				fontStyle: 'normal',
				fontWeight: '700',
				fontSize: '22px',
				lineHeight: '26px',
				letterSpacing: '0.01em',
				color: '#828282' }}>
			<span>{minutes}</span>:<span>{seconds}</span>
		</div>
	  </div >
	);
}


export default OTPTimer;
